// Bu dosya, ekip alımları sayfasını doldurmak için oluşturuldu.
// {
//     title: "",           -- Resmin altında, açıklamanın üstünde gözükecek yazı.
//     subtitle: "",        -- Resmin ve title'ın altında gözükecek, açıklama yazısı.
//     image: "",           -- Resim linki.
//     link: "",            -- Kullanıcının butona tıkladığında yönlendirileceği link.
//     button_text: "",     -- is_active 1'se buton gösterilir. Bu butonun içerisindeki yazı.
//     is_active: 1         -- 0 yaparak butonu deaktif hale getirebilirsiniz.
// }

module.exports = [
    {
        title: "Çevirmen",           // String: Resmin altında, açıklamanın üstünde gözükecek yazı.
        subtitle: "Çeviriyi yapan insan",        // String: Resmin ve title'ın altında gözükecek, açıklama yazısı.
        image: "https://c.tenor.com/x1CgQPef0J4AAAAC/tenor.gif",           // String: Resim linki.
        link: "https://docs.google.com/forms/d/1Z81t2ao41I_o52gH9BMTBiO38iBFmzs6UdejTA4l4Tg/viewform?edit_requested=true",            // String: Kullanıcının butona tıkladığında yönlendirileceği link.
        button_text: "Alımlar Açıktır",     // String: is_active 1'se buton gösterilir. Bu butonun içerisindeki yazı.
        is_active: 1         // Number: 0 yaparak butonu deaktif hale getirebilirsiniz.
    }
]