//Bu dosyayı temanızı kendi değerlerinizle değiştirmek için kullanabilirsiniz.
//Değerleri değiştirmek için MUI paketindeki theme objesine bağlı kalarak değişiklik
//yapmanız gerektiğini unutmayın.
//Theme object: https://material-ui.com/customization/default-theme/
//      ÖRNEK
//      theme = {
//          palette: {
//              primary: {
//                  main: COLORHEXCODE
//              }
//          }
//      }
const theme = {
    palette: {
        primary: {
            main: "#F9B2F8"
        }
    }
}

export default theme